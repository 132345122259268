<template>
  <div>
    <div class="mainpage-header">
      <div style="vertical-align: baseline; float: left">
        <router-link to="/">
          <img class="app-logo" src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk.png">
        </router-link>
      </div>
      <div v-if="!user.uuid" style="float: right;">
        <div v-if="!openCloseLoginForm" @click="openCloseLoginForm = true">
          <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_menu_black.svg"/>
        </div>
        <div v-if="openCloseLoginForm" @click="openCloseLoginForm = false">
          <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_menu_close_black.svg"/>
        </div>
      </div>
    </div>

    <div class="settings" v-if="user.uuid || !openCloseLoginForm" style=" padding-top: 60px; min-width: 100vw">
      <div class="pro-landing-header">
        <div style="margin-top: 70px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <p class="header-title">
            <span style="font-weight: 300;">Bionluk</span> Pro'yu Keşfet
          </p>
          <p class="header-description">
            Suspendisse eget justo orci. Vestibulum ante ipsum primis in faucibus orci luctus et
          </p>
          <div class="header-button">Hemen Keşfet</div>
        </div>
        <div class="header-bottom-div">
          <div class="title-body">
            <div :class="{'title-main': true ,active: activeCategory === category.id}"
                 v-for="category in categoryList"
                 @click="makeActive(category)">
              <p class="title">{{category.name}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="pro-top-body">
        <div class="pro-card-array">
          <div class="pro-card-body" v-for="card in cardsArray">
            <img class="card-image" :src="card.imageUrl"/>
            <router-link class="card-user-info" :to="card.username">
              <img class="card-avatar" :src="card.avatarUrl"/>
              <div class="card-user-info-inner">
                <p class="card-user-info-username">{{card.username}}</p>
                <p class="card-user-info-title">{{card.title}}</p>
              </div>
            </router-link>
            <img class="pro-card-icon" src="https://gcdn.bionluk.com/site/icon/proBadge_new_3x.png"/>
            <div class="card-user-text"><p class="card-user-text-inner">{{card.text}}</p></div>
          </div>

        </div>
        <img class="pro-icon-big" src="https://gcdn.bionluk.com/site/general/proBadge.png"/>
      </div>

      <div class="pro-center-body">
        <div class="pro-center-infos">
          <img src="https://gcdn.bionluk.com/site/general/pro_i_1.png"/>
          <p class="pro-center-title">Top Quality</p>
          <p class="pro-center-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis placerat aliquam nibh et fermentum. In vel risus
            dignissim, aliquam quam non, pulvinar massa. Nulla suscipit, risus sed pulvinar dictum, neque erat convallis eros, vitae volutpat diam justo sit amet
            nibh. Proin pharetra ipsum vel vestibulum porttitor.</p>
        </div>
        <div class="pro-center-infos">
          <img src="https://gcdn.bionluk.com/site/general/pro_i_2.png"/>
          <p class="pro-center-title">Top Quality</p>
          <p class="pro-center-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis placerat aliquam nibh et fermentum. In vel risus
            dignissim, aliquam quam non, pulvinar massa. Nulla suscipit, risus sed pulvinar dictum, neque erat convallis eros, vitae volutpat diam justo sit amet
            nibh. Proin pharetra ipsum vel vestibulum porttitor.</p>
        </div>
        <div class="pro-center-infos" style="margin-right: 0">
          <img src="https://gcdn.bionluk.com/site/general/pro_i_3.png"/>
          <p class="pro-center-title">Top Quality</p>
          <p class="pro-center-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis placerat aliquam nibh et fermentum. In vel risus
            dignissim, aliquam quam non, pulvinar massa. Nulla suscipit, risus sed pulvinar dictum, neque erat convallis eros, vitae volutpat diam justo sit amet
            nibh. Proin pharetra ipsum vel vestibulum porttitor.</p>
        </div>
      </div>

      <div class="pro-bottom-body">
        <div class="slider-body">
          <carousel :navigationEnabled="false" :perPage="1" class="slider-main">
            <slide v-for="(slider, index) in sliderData" :key="index" class="slider-inner">
              <p class="slider-user-text">"{{slider.text}}"</p>
              <img class="slider-user-image" :src="slider.imageUrl"/>
              <p class="slider-user-username">{{slider.username}}</p>
              <p class="slider-user-title">{{slider.title}}</p>
            </slide>
          </carousel>
        </div>

        <div class="pro-footer-body">
          <p>Sen de satıcı olmak ister misin?</p>
          <div>Hemen Başla</div>
        </div>
      </div>


    </div>
    <div v-if="!user.uuid && openCloseLoginForm" class="mainpage-mobile-login-form">

      <button class="super-button-light login-form-button-explore"
              @click="$router.push('/freelancer-bul')">Bionluk'u Keşfet
      </button>
      <div style="width: 120px; height: 2px;border-bottom: solid 1px #dde3eb; margin-top: 31px; margin-bottom: 29px;"></div>
      <button class="super-button-light login-form-button-freelancer"
              @click="$router.push('/register')">Freelancer Ol
      </button>
      <button class="super-button login-form-button-login"
              @click="$router.push('/login')">Giriş / Kaydol
      </button>
      <div style="width: 271px; height: 3px; border-bottom: solid 1px #dde3eb; margin-top: 85px;"></div>

      <div>
        <div style="margin-top: 20px; margin-bottom: 20px;display: flex;align-items: center">
          <div style="cursor: pointer;">
            <a href="https://facebook.com/bionluk" target="_blank">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_facebook_gri.svg"/>
            </a>
          </div>

          <div style="cursor: pointer; margin-left: 30px;;">
            <a href="https://twitter.com/bionluk" target="_blank">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_twitter_gri.svg"/>
            </a>
          </div>

          <div style="cursor: pointer; margin-left: 30px;">
            <a href="https://instagram.com/bionlukcom" target="_blank">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_instagram_gri.svg"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "src-pages-body-main-proLanding-pro_mobile",
    data() {
      return {
        cardsArray: [
          {
            avatarUrl: 'https://i.bionluk.com/images/avatar/200x200/cutecatrULgD37.jpg',
            username: 'michelledoe',
            text: 'Etiam aliquam purus a augue pulvinar, vitae suscipit ipsum pharetra. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia ',
            title: 'Tasarimci',
            imageUrl: 'https://i.bionluk.com/images/portfolio/526x296/7802ae40-956a-4282-b57f-365d63f1388b.png'
          },
          {
            avatarUrl: 'https://i.bionluk.com/images/avatar/200x200/cutecatrULgD37.jpg',
            username: 'michelledoe',
            text: 'Etiam aliquam purus a augue pulvinar, vitae suscipit ipsum pharetra. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia ',
            title: 'Tasarimci',
            imageUrl: 'https://i.bionluk.com/images/portfolio/526x296/7802ae40-956a-4282-b57f-365d63f1388b.png'
          },
          {
            avatarUrl: 'https://i.bionluk.com/images/avatar/200x200/cutecatrULgD37.jpg',
            username: 'michelledoe',
            text: 'Etiam aliquam purus a augue pulvinar, vitae suscipit ipsum pharetra. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia ',
            title: 'Tasarimci',
            imageUrl: 'https://i.bionluk.com/images/portfolio/526x296/7802ae40-956a-4282-b57f-365d63f1388b.png'
          },
          {
            avatarUrl: 'https://i.bionluk.com/images/avatar/200x200/cutecatrULgD37.jpg',
            username: 'michelledoe',
            text: 'Etiam aliquam purus a augue pulvinar, vitae suscipit ipsum pharetra. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia ',
            title: 'Tasarimci',
            imageUrl: 'https://i.bionluk.com/images/portfolio/526x296/7802ae40-956a-4282-b57f-365d63f1388b.png'
          },

        ],
        sliderData: [
          {
            imageUrl: 'https://d2fpniz7ghe4fz.cloudfront.net/testi/gunes.jpg?go5',
            text: 'Ucuz olduğu için Bionluk`ta alışveriş yapmıyorduk. Ama ekstra seçeneklerle almak istediğimiz kalitede bir hizmet alabileceğimizi farkettik. Bionluk`ta ihtiyacımız olan işleri kolay ve hızlı bir şekilde yaptırabiliyoruz',
            username: 'Güneş Gülbiter',
            title: 'Sosyal Medya Direktörü, Scorp App'
          },
          {
            imageUrl: 'https://d2fpniz7ghe4fz.cloudfront.net/testi/gunes.jpg?go5',
            text: 'Ucuz olduğu için Bionluk`ta alışveriş yapmıyorduk. Ama ekstra seçeneklerle almak istediğimiz kalitede bir hizmet alabileceğimizi farkettik. Bionluk`ta ihtiyacımız olan işleri kolay ve hızlı bir şekilde yaptırabiliyoruz',
            username: 'Güneş Gülbiter',
            title: 'Sosyal Medya Direktörü, Scorp App'
          }
        ],
        activeCategory: 1,
        categoryList: [
          {
            name: 'Grafik Tasarim',
            id: 1
          },
          {
            name: "İnternet Reklamcılığı",
            id: 2
          },
          {
            name: 'Yazi Ceviri',
            id: 3
          },
          {
            name: "Video Animasyon",
            id: 4
          },
          {
            name: 'Ses Muzik',
            id: 5
          }
        ],
        openCloseLoginForm: false
      }
    },

    methods: {
      makeActive(activeCategory) {
        this.activeCategory = activeCategory.id;
        // istek cikilacak burda
      },
      reverseFaq(faq) {
        faq.open = !faq.open;
      },

      openCardModal(card) {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.FOR_FREELANCERS, info: card});

      }
    },

    watch: {},

    created() {
    }

  }
</script>

<style scoped lang="scss">

  .mainpage-header {
    padding-top: 20px;
    padding-right: 15px;
    padding-left: 13px;
  }

  .app-logo {
    height: 28px;
    margin-top: 2px;
  }

  /*login part*/
  .mainpage-mobile-login-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 100vw;
    height: 90vh
  }

  .mainpage-mobile-login-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 100vw;
    height: 90vh
  }

  .login-form-button-explore {
    width: 270px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .login-form-button-freelancer {
    width: 270px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px #2d3640;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .login-form-button-login {
    width: 270px;
    height: 50px;
    border-radius: 2px;
    background-color: #2d3640;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
  }

  /*page */
  .pro-landing-header {
    margin: 0 auto;
    text-align: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header-title {
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #2d3640;

    }
    .header-description {
      width: 291px;
      margin-top: 20px;
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #8b95a1;

    }

    .header-button {
      width: 193px;
      height: 50px;
      border-radius: 2px;
      background-color: #fd4056;
      margin: 0 auto;
      margin-top: 20px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      cursor: pointer;
    }
  }

  .header-bottom-div {
    margin-top: 53px;

  }

  .title-body {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    overflow-x: scroll;
  }

  .title-main {
    cursor: pointer;
    padding-right: 26px;
    padding-left: 26px;
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8b95a1;
    .title {
      text-align: center;
    }
  }

  .active {
    margin-bottom: 0;
    border-bottom: 3px solid #fd4056;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fd4056;
  }

  /*513+716+330=1559*/
  /*top*/
  .pro-top-body {
    min-width: 100vw;
    padding-bottom: 105px;
    padding-top: 20px;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .pro-card-array {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

  }

  .pro-icon-big {
    position: absolute;
    width: 178px;
    height: 205px;
    bottom: -115px;

  }

  .pro-card-body {
    -webkit-box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }

    width: 265px;
    height: 350px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    position: relative;
    .card-image {
      width: 265px;
      height: 149px;
    }

    .pro-card-icon {
      position: absolute;
      top: 130px;
      right: 15px;
      width: 28px;
      height: 40px;
    }
  }

  .card-user-info {
    padding: 17px 15px 0 20px;
    display: flex;
    align-items: center;
    .card-avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }

    .card-user-info-inner {
      margin-left: 11px;
      .card-user-info-username {
        font-size: 14px;
        font-weight: 600;
        color: #4b4f52;
      }

      .card-user-info-title {
        margin-top: 1px;
        font-size: 12px;
        text-align: left;
        color: #8b95a1;
      }
    }
  }

  .card-user-text {
    padding: 20px 15px 18px 20px;
    .card-user-text-inner {
      width: 230px;
      height: 111px;
      overflow: hidden;
      font-size: 16px;
      line-height: 1.38;
      text-align: left;
      color: #4b4f52;
    }
  }

  /*center*/
  .pro-center-body {
    min-width: 100vw;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }

  .pro-center-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    &:first-child {
      margin-top: 116px;
    }
    img {
      width: 89px;
      height: 89px;
    }

    .pro-center-title {
      margin-top: 13px;
      font-size: 30px;
      font-weight: 600;
      color: #2d3640;
    }
    .pro-center-description {
      width: 270px;
      margin-top: 14px;
      font-size: 16px;
      line-height: 1.5;
      color: #8b95a1;
      text-align: center;
    }
  }

  /*bottom */
  .pro-bottom-body {
    min-width: 100vw;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slider-body {
    margin-top: 15px;
    max-width: 945px;
    width: 90vw;
    height: 435px;
    background-color: #ffffff;
    border: solid 1px #f4f5f7;
    padding-top: 20px;
    -webkit-box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    .slider-main {
      max-width: 945px;
      width: 90vw;
      margin: 0 auto;
    }
  }

  .slider-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 945px;
    width: 90vw;
    margin-bottom: 30px;
    .slider-user-image {
      margin-top: 30px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .slider-user-text {
      width: calc(90vw - 20px);
      max-width: 610px;
      max-height: 250px;
      overflow-y: scroll;
      font-family: Georgia;
      font-size: 22px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.55;
      letter-spacing: normal;
      text-align: center;
      color: #2d3640;
    }

    .slider-user-username {
      margin-top: 17px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #2d3640;
    }

    .slider-user-title {
      margin-top: 7px;
      font-size: 12px;
      text-align: left;
      color: #8b95a1;
    }

  }

  /*footer */

  .pro-footer-body {
    margin-top: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-width: 100vw;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(https://gcdn.bionluk.com/site/general/pro_red_bg.png);
    p {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      width: 230px;
      margin-top: 30px;
    }

    div {
      margin-top: 20px;
      margin-bottom: 43px;
      width: 200px;
      height: 50px;
      border-radius: 2px;
      background-color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
      line-height: 50px;
    }
  }
</style>
